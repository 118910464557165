class Enum {
    static GetAll(){
        let ris = [];
        for(let p in this){
            ris.push(this[p]);
        }
        return ris;
    }
    static GetDictionary(){
        let ris = {};
        for(let p in this){
            ris[p] = this[p];
        }
        return ris;
    }
}

export class DataTypes extends Enum
{
    static String: string = "string";
    static Date: string = "date";
    static Boolean: string = "boolean";
    static Integer: string = "integer";
    static Float: string = "float";
}

export class DataFromApi extends Enum
{
    static clienti: string = "clienti";
    static immobili: string = "immobili";
    static attivita: string = "attivita";
    static task: string = "task";
    static cronologia: string = "cronologia";
    static user: string = "user";
    static gruppi: string = "gruppi";
    static appuntamenti: string = "appuntamenti";
    static relazioniImmobiliContatti: string = "relazioniImmobiliContatti";
    static relazioniGruppiImmobiliUnitaImmobili: string = "relazioniGruppiImmobiliUnitaImmobili";
    static relazioniContattiUtenti: string = "relazioniContattiUtenti";
    static relazioniContattiAttivita: string = "relazioniContattiAttivita";
    static relazioniImmobiliAttivita: string = "relazioniImmobiliAttivita";
}