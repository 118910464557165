import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("label", null, [
      _createElementVNode("b", null, "Ora di avvio")
    ], -1)),
    _withDirectives(_createElementVNode("input", {
      type: "number",
      class: "form-control",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.startingHour) = $event))
    }, null, 512), [
      [_vModelText, _ctx.localValue.startingHour]
    ])
  ]))
}