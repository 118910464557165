<template>
    <div class="form-group">
        <label><b>Nome tabella creata</b></label>
        <input type="text" class="form-control" v-model="localValue.tableName" />
    </div>
    <div class="form-group" v-if="localValue.type == 'CsvFileImportConfig'">
        <label><b>Delimitatore campi CSV</b></label>
        <b v-if="!(localValue as any).delimiter" class="text-danger">REQUIRED</b>
        <input type="text" class="form-control" v-model="(localValue as any).delimiter" />
    </div>
    <div class="mt-3 mb-2">
        <b>Tipizzazione colonne</b> - <button class="btn btn-sm btn-primary" @click="loadHeaders">Carica colonne dal file selezionato</button>
    </div>
    <op-table class="table table-striped" :items="localValue.dataTypes" v-slot:default="opData" :showUpperPagination="false">
        <thead>
            <tr>
                <td filter="name" sort="name">Nome</td>
                <td filter="dataType" sort="dataType">Tipo</td>
                <td filter="dataType" sort="dataType">Formato</td>
                <td>
                    <button class="btn btn-sm btn-success squaredPlus" @click="add">+</button>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr v-if="opData.items.length == 0">
                <td colspan="99">
                    Nessuno
                </td>
            </tr>
            <tr v-for="(item, i) in opData.items as OM.DataTypeDefinition[]" :key="i">
                <td>
                    <input type="text" v-model="item.name" placeholder="Header colonna" />
                </td>
                <td>
                    <button class="btn btn-sm btn-link" :class="{ active: item.dataType == dt }" v-for="dt in dataTypes" @click="item.dataType = dt">
                        {{dt}}
                    </button>
                    <!-- <select v-model="item.dataType">
                        <option v-for="item in dataTypes" :value="item">{{item}}</option>
                    </select> -->
                </td>
                <td>
                    <b v-if="item.dataType == 'date' && !item.dateFormat" class="text-danger">REQUIRED</b>
                    <select v-model="item.dateFormat" v-if="item.dataType == 'date'">
                        <option value="it-IT">Italiana</option>
                        <option value="en-GB">Inglese</option>
                    </select>
                </td>
                <td>
                    <button class="btn btn-sm btn-danger" @click="removeThis(item, i)">
                        x
                    </button>
                </td>
            </tr>
        </tbody>
    </op-table>
</template>

<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import * as CONST from '@/const';
import WithModel from '@/mixins/withModel';
import dataTypeDefinition from './dataTypeDefinition.vue';
import { AppConfigClient } from '@/services/Services';

@Options({
    components: {
        dataTypeDefinition,
    }
})
export default class excelLikeFileConfig extends mixins(WithModel) {
    
    declare localValue: OM.ExcelLikeFileConfig;
    
    dataTypes = CONST.DataTypes.GetAll();
    
    nodes = {};
    
    loadHeaders(){
        this.localValue.dataTypes = [];
        AppConfigClient.readFileHeaders(this.localValue.filePath, this.localValue.type, (this.localValue as any).delimiter ? (this.localValue as any).delimiter : "")
        .then(x => {
            this.localValue.dataTypes = x;
        })
    }
    
    add(){
        let toPush = new OM.DataTypeDefinition();
        this.localValue.dataTypes.push(toPush);
    }
    
    removeThis(item: OM.DataTypeDefinition, index: number){
        this.localValue.dataTypes.splice(index, 1);
    }
    
}
</script>
