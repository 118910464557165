import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "form-group"
}
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = { class: "mt-3 mb-2" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("label", null, [
        _createElementVNode("b", null, "Nome tabella creata")
      ], -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.tableName) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.tableName]
      ])
    ]),
    (_ctx.localValue.type == 'CsvFileImportConfig')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("label", null, [
            _createElementVNode("b", null, "Delimitatore campi CSV")
          ], -1)),
          (!(_ctx.localValue as any).delimiter)
            ? (_openBlock(), _createElementBlock("b", _hoisted_3, "REQUIRED"))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (((_ctx.localValue as any).delimiter) = $event))
          }, null, 512), [
            [_vModelText, (_ctx.localValue as any).delimiter]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _cache[6] || (_cache[6] = _createElementVNode("b", null, "Tipizzazione colonne", -1)),
      _cache[7] || (_cache[7] = _createTextVNode(" - ")),
      _createElementVNode("button", {
        class: "btn btn-sm btn-primary",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.loadHeaders && _ctx.loadHeaders(...args)))
      }, "Carica colonne dal file selezionato")
    ]),
    _createVNode(_component_op_table, {
      class: "table table-striped",
      items: _ctx.localValue.dataTypes,
      showUpperPagination: false
    }, {
      default: _withCtx((opData) => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[8] || (_cache[8] = _createElementVNode("td", {
              filter: "name",
              sort: "name"
            }, "Nome", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("td", {
              filter: "dataType",
              sort: "dataType"
            }, "Tipo", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("td", {
              filter: "dataType",
              sort: "dataType"
            }, "Formato", -1)),
            _createElementVNode("td", null, [
              _createElementVNode("button", {
                class: "btn btn-sm btn-success squaredPlus",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)))
              }, "+")
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          (opData.items.length == 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_5, _cache[11] || (_cache[11] = [
                _createElementVNode("td", { colspan: "99" }, " Nessuno ", -1)
              ])))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opData.items as OM.DataTypeDefinition[], (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((item.name) = $event),
                  placeholder: "Header colonna"
                }, null, 8, _hoisted_6), [
                  [_vModelText, item.name]
                ])
              ]),
              _createElementVNode("td", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataTypes, (dt) => {
                  return (_openBlock(), _createElementBlock("button", {
                    class: _normalizeClass(["btn btn-sm btn-link", { active: item.dataType == dt }]),
                    onClick: ($event: any) => (item.dataType = dt)
                  }, _toDisplayString(dt), 11, _hoisted_7))
                }), 256))
              ]),
              _createElementVNode("td", null, [
                (item.dataType == 'date' && !item.dateFormat)
                  ? (_openBlock(), _createElementBlock("b", _hoisted_8, "REQUIRED"))
                  : _createCommentVNode("", true),
                (item.dataType == 'date')
                  ? _withDirectives((_openBlock(), _createElementBlock("select", {
                      key: 1,
                      "onUpdate:modelValue": ($event: any) => ((item.dateFormat) = $event)
                    }, _cache[12] || (_cache[12] = [
                      _createElementVNode("option", { value: "it-IT" }, "Italiana", -1),
                      _createElementVNode("option", { value: "en-GB" }, "Inglese", -1)
                    ]), 8, _hoisted_9)), [
                      [_vModelSelect, item.dateFormat]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("button", {
                  class: "btn btn-sm btn-danger",
                  onClick: ($event: any) => (_ctx.removeThis(item, i))
                }, " x ", 8, _hoisted_10)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ], 64))
}